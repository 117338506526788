import { createModule, action, mutation } from 'vuex-class-component'
import Vue from 'vue'
import jwt from 'jwt-decode'

const VuexModule = createModule({
  strict: false,
  namespaced: 'login',
})

export default class extends VuexModule {
  private token = ''
  private authId = ''
  private exp = 0
  private username = ''

  get isTokenExpire() {
    return this.exp - Date.now() < 0
  }
  get isLogin() {
    return this.token !== '' && !this.isTokenExpire
  }
  get getToken() {
    return this.token
  }

  get myUsername() {
    return this.username
  }

  @mutation updateToken(payload: string) {
    this.token = payload
  }
  @mutation updateAuthId(payload: string) {
    this.authId = payload
  }
  @mutation updateExp(payload: number) {
    this.exp = payload
  }
  @mutation updateUsername(payload: string) {
    this.username = payload
  }

  @action async login(ticket: string) {
    const { data } = await Vue.axios.get<string>(
      `${process.env.VUE_APP_AUTH_URL}/auth/token?ticket=${ticket}`
    )
    if (data) {
      const decode = jwt<{ authId: string; exp: number; username: string }>(
        data
      )
      if (decode.username.slice(0, 1) === '9') {
        this.updateUsername(decode.username)
        this.updateToken(data)
        this.updateAuthId(decode.authId)
        this.updateExp(decode.exp * 1000)
        Vue.axios.defaults.headers = { authorization: `Bearer ${data}` }
      } else {
        this.logout()
      }
    }
  }
  @action async logout() {
    this.updateToken('')
    this.updateAuthId('')
    this.updateExp(0)
    this.updateUsername('')
    if (process.env.VUE_APP_AUTH_FRONTEND_URL && process.env.VUE_APP_BASE_URL) {
      window.location.replace(
        `${
          process.env.VUE_APP_AUTH_FRONTEND_URL
        }/logout?redirect=${encodeURIComponent(process.env.VUE_APP_BASE_URL)}`
      )
    }
  }
}
