export enum UserPosition {
  Student = 'Student',
  Staff = 'Staff',
}

export interface AllTransaction {
  id: string
  username: string
  firstName: string
  firstNameEn: string
  lastName: string
  lastNameEn: string
  nickname: string
  nicknameEn: string
  email?: string
  position: UserPosition
  phone: string[]
  walletSummaries: WalletSummary[]
}

export interface WalletSummary {
  subject: string
  transactions: Transaction[]
  wallets: Wallet[]
}

export enum TransactionName {
  bt = 'BT',
  bc = 'BC',
  yc = 'YC',
  cv = 'CV',
  nf = 'NF',
  cvn = 'CVN',
  empty = '',
}

export enum CoinTransactionSubject {
  math = 'math',
  physics = 'physics',
  chem = 'chem',
  english = 'english',
}

export interface Transaction {
  ref: string
  amount: number
  displayDate: string | Date
  expireIn?: string | null
  tags: CoinTransactionTag[]
  name?: TransactionName
  remark?: string
  transferRef?: TransferRef
}

export interface TransferRef {
  transferNumber: string
  transferDate: string
}

export enum CoinTransactionTag {
  Fhb = 'Fhb',
  Refund = 'Refund',
  Fine = 'Fine',
  TopUp = 'TopUp',
  Absent = 'Absent',
  Drop = 'Drop',
  Expired = 'Expired',
  Promotion = 'Promotion',
}

export interface Wallet {
  name?: TransactionName
  balance: number
  expiryDate?: string
  expireIn?: number
}

export interface CoinTransactionByUsernameInput {
  username: string
  ref?: string
  amount: number
  displayDate?: Date
  expireIn?: number
  subject: CoinTransactionSubject
  tags: CoinTransactionTag[]
  remark?: string
  name: TransactionName
  transferRef?: TransferRef
}
