import Vue from 'vue'
import Vuefity from 'vuetify'
import App from './App.vue'
import router from './router'
import { store, vxm } from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/scss/materialdesignicons.scss'
import { theme } from '@/theme'
import firebase from 'firebase/app'
import 'firebase/analytics'
import apolloProvider from '@/utils/graphql'
import VuePageTransition from 'vue-page-transition'

const vuetify = new Vuefity({
  theme: {
    dark: vxm.themeToggle.isDarkMode,
    themes: theme,
  },
  icons: {
    iconfont: 'mdi',
  },
})

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()

Vue.config.productionTip = false
Vue.use(Vuefity)
Vue.use(VueAxios, axios)
Vue.use(VuePageTransition)

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
