import { createModule, mutation } from 'vuex-class-component'

const VuexModule = createModule({
  namespaced: 'themeToggle',
  strict: false,
})

export default class extends VuexModule {
  private isDark = false

  @mutation toggleDarkmode() {
    this.isDark = !this.isDark
  }

  get isDarkMode() {
    return this.isDark
  }
}
