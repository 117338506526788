





































































import { Component, Vue } from 'vue-property-decorator'
import NavList from '@/components/Navlist.vue'
import { vxm } from '@/store'

@Component({
  components: {
    NavList,
  },
})
export default class Navigation extends Vue {
  get opened() {
    return vxm.drawer.getDrawerState
  }
  changeDrawer(state: boolean) {
    if (!state) {
      vxm.drawer.changeDrawerState(state)
    }
  }
}
