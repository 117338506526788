import { createModule, mutation } from 'vuex-class-component'

const VuexModule = createModule({
  strict: false,
  namespaced: 'globalSearch',
})

export default class extends VuexModule {
  private value = ''

  get getVal() {
    return this.value
  }

  @mutation setVal(val: string) {
    this.value = val
  }

  @mutation clearVal() {
    this.value = ''
  }
}
