import { createModule, action, mutation } from 'vuex-class-component'
import Vue from 'vue'
import { Student, AllStudents, StudentsForSearch } from '@/types/allUsers'
import { CoinTransactionSubject } from '@/types/transaction'

const url = process.env.VUE_APP_MAIN_URL
const token = process.env.VUE_APP_TOKEN

const VuexModule = createModule({
  strict: false,
  namespaced: 'allUsers',
})

export default class extends VuexModule {
  private users: Student[] = []

  get allStudents() {
    return this.users
  }
  get getStudentNameById(): (id: number) => Student | undefined {
    return (id: number) => {
      return this.users.find(o => o.ID === id)
    }
  }
  get studentsWithSubj() {
    return this.users.reduce<StudentsForSearch[]>((res, curr) => {
      return [
        ...res,
        {
          id: curr.ID,
          firstname: curr.firstname,
          nickname: curr.nickname,
          subject: CoinTransactionSubject.math,
        },
        {
          id: curr.ID,
          firstname: curr.firstname,
          nickname: curr.nickname,
          subject: CoinTransactionSubject.physics,
        },
        {
          id: curr.ID,
          firstname: curr.firstname,
          nickname: curr.nickname,
          subject: CoinTransactionSubject.chem,
        },
        {
          id: curr.ID,
          firstname: curr.firstname,
          nickname: curr.nickname,
          subject: CoinTransactionSubject.english,
        },
      ]
    }, [])
  }
  get studentsForAutoComplete() {
    return this.studentsWithSubj.map(el => {
      switch (el.subject) {
        case CoinTransactionSubject.math:
          return {
            text: `${el.id}1(${el.subject.slice(0, 1).toUpperCase()}): ${
              el.firstname
            }(${el.nickname})`,
            value: `${el.id}1`,
          }
        case CoinTransactionSubject.physics:
          return {
            text: `${el.id}2(${el.subject.slice(0, 1).toUpperCase()}): ${
              el.firstname
            }(${el.nickname})`,
            value: `${el.id}2`,
          }
        case CoinTransactionSubject.english:
          return {
            text: `${el.id}4(${el.subject.slice(0, 1).toUpperCase()}): ${
              el.firstname
            }(${el.nickname})`,
            value: `${el.id}4`,
          }
        default:
          return {
            text: `${el.id}3(${el.subject.slice(0, 1).toUpperCase()}): ${
              el.firstname
            }(${el.nickname})`,
            value: `${el.id}3`,
          }
      }
    })
  }

  @mutation
  updateUsers(payload: Student[]) {
    this.users = payload
  }

  @action
  async fetchUsers() {
    const res = await Vue.axios.get<AllStudents>(
      `${url}/v1/user/activeStudent`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    this.updateUsers(res.data.students)
  }
}
