import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { extractVuexModule, createProxy } from 'vuex-class-component'
import Drawer from './modules/drawer'
import ThemeToggle from './modules/themeToggle'
import Transaction from './modules/transaction'
import UserTransaction from './modules/userTransaction'
import AllUsers from './modules/allUsers'
import GlobalSearch from './modules/globalSearch'
import Login from './modules/login'
import { VuexObject } from 'vuex-class-component/dist/interfaces'
import Quarter from './modules/quarter'
import Timelog from './modules/timelog'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['themeToggle', 'allUsers', 'timelog'],
})
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['login'],
})

export const store = new Vuex.Store<VuexObject>({
  modules: {
    ...extractVuexModule(Drawer),
    ...extractVuexModule(ThemeToggle),
    ...extractVuexModule(Transaction),
    ...extractVuexModule(UserTransaction),
    ...extractVuexModule(AllUsers),
    ...extractVuexModule(GlobalSearch),
    ...extractVuexModule(Login),
    ...extractVuexModule(Quarter),
    ...extractVuexModule(Timelog),
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin],
})

export const vxm = {
  drawer: createProxy(store, Drawer),
  themeToggle: createProxy(store, ThemeToggle),
  transaction: createProxy(store, Transaction),
  userTransaction: createProxy(store, UserTransaction),
  allUsers: createProxy(store, AllUsers),
  globalSearch: createProxy(store, GlobalSearch),
  login: createProxy(store, Login),
  quarter: createProxy(store, Quarter),
  timelog: createProxy(store, Timelog),
}
