









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Navlist extends Vue {
  @Prop({ default: '' }) private title!: string
  @Prop({ default: '' }) private icon!: string
  @Prop({ default: '' }) private path!: string
}
