import { StudentClass } from './classes'

export enum QuarterType {
  Normal = 'Normal',
  Summer = 'Summer',
}

export interface Quarter {
  id: number
  name: string
  type: QuarterType
  startDate: string
  endDate: string
  isActive: boolean
  remark: null | '' | 'onRegis'
  classes: Partial<StudentClass>[]
}
