import { createModule, action, mutation } from 'vuex-class-component'
import { apolloClient } from '../../utils/graphql'
import { Quarter, QuarterType } from '../../types/quarters'
import gql from 'graphql-tag'

const VuexMoudle = createModule({
  namespaced: 'quarter',
  strict: false,
})

export default class extends VuexMoudle {
  private loading = false
  private quarters: Partial<Quarter>[] = []
  private active: Partial<Quarter> = {}
  private fetched = false

  get isLoading() {
    return this.loading
  }

  get allQuarters() {
    return this.quarters
  }

  get activeQuarter() {
    return this.active
  }

  @mutation
  private setFetched(fetched: boolean) {
    this.fetched = fetched
  }

  @mutation
  setLoading(loading: boolean) {
    this.loading = loading
  }

  @mutation
  setQuarters(quarters: Partial<Quarter>[]) {
    this.quarters = quarters
  }

  @mutation
  setActiveQuarter(quarter: Partial<Quarter>) {
    this.active = quarter
  }

  @mutation setActiveQuarterById(quarterId: number) {
    const findQ = this.quarters.find(el => el.id === quarterId)
    if (findQ) {
      this.active = findQ
    }
  }

  @action
  async fetchQuarters() {
    this.setLoading(true)
    const { data } = await apolloClient.query<{
      quarters: Partial<Quarter>[]
    }>({
      query: gql`
        query GetAllQuarter {
          quarters {
            id
            name
            type
            isActive
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
    if (data) {
      this.setQuarters(data.quarters.reverse())
      if (!this.fetched) {
        this.setFetched(true)
        const activeQ = data.quarters.find(
          el => el.type === QuarterType.Normal && el.isActive
        )
        if (activeQ) {
          this.setActiveQuarter(activeQ)
        }
      }
    }
    this.setLoading(false)
  }
}
