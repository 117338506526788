













































import { Component, Vue } from 'vue-property-decorator'
import { vxm } from '@/store'
import { FileResponse } from '@/types/user'

@Component({
  computed: {
    search: {
      get: () => {
        return vxm.globalSearch.getVal
      },
      set: value => {
        return vxm.globalSearch.setVal(value)
      },
    },
    isDark: () => vxm.themeToggle.isDarkMode,
  },
})
export default class extends Vue {
  private onSearch = false
  private avatarUrl: string = require('@/assets/avatar.jpg')

  mounted() {
    this.getProfileImage()
    window.onkeyup = (e: KeyboardEvent) => {
      const key = e.keyCode ? e.keyCode : e.which
      if (key == 191) {
        // Slash key
        this.focusInput()
      }
      if (key === 27) {
        // Esc key
        this.clearInput()
      }
    }
  }

  beforeDestroy() {
    window.onkeyup = null
  }

  private async getProfileImage() {
    try {
      const profile = await this.axios.get<FileResponse>(
        `${process.env.VUE_APP_OFFICE_URL}/user/profile`,
        {
          headers: {
            authorization: `Bearer ${vxm.login.getToken}`,
          },
        }
      )
      if (profile.data) {
        this.avatarUrl = profile.data.signedUrl
      }
    } catch (error) {
      // empty
    }
  }

  private focusInput() {
    this.onSearch = true
    setTimeout(() => {
      const field = this.$refs.field as HTMLInputElement
      field.focus()
    }, 100)
  }

  private clearInput() {
    this.onSearch = false
    vxm.globalSearch.clearVal()
  }

  private toggleDrawer() {
    vxm.drawer.toggleDrawerState()
  }

  private toggleTheme() {
    vxm.themeToggle.toggleDarkmode()
    this.$vuetify.theme.dark = vxm.themeToggle.isDarkMode
  }

  private async logout() {
    await vxm.login.logout()
  }
}
