import { createModule, mutation, action } from 'vuex-class-component'
import { apolloClient } from '@/utils/graphql'
import gql from 'graphql-tag'
import { AllTransaction, CoinTransactionTag } from '@/types/transaction'
import { vxm } from '@/store'

const VuexModule = createModule({
  namespaced: 'transaction',
  strict: false,
})

export default class extends VuexModule {
  private onFetch = false
  private margin = 999
  private transaction: AllTransaction[] = []

  get getFetchStatus() {
    return this.onFetch
  }

  get getTransaction() {
    return this.transaction
  }

  get getSummaryTransaction() {
    const res = []
    for (const i of this.transaction) {
      for (const j of i.walletSummaries) {
        const data = {
          id: i.id,
          username: i.username,
          firstName: i.firstName,
          firstNameEn: i.firstNameEn,
          lastName: i.lastName,
          lastNameEn: i.lastNameEn,
          nickname: i.nickname,
          nicknameEn: i.nicknameEn,
          email: i.email,
          position: i.position,
          phone: i.phone,
          total: j.wallets.reduce<number>((total, current) => {
            return total + current.balance
          }, 0),
        }
        let lastTU = 0
        let lastTuDate = new Date(0)
        let lastTfNum = ''
        let lastTfDate = new Date(0)
        let lastName = undefined
        for (const k of j.transactions) {
          if (k.tags.indexOf(CoinTransactionTag.TopUp) >= 0) {
            const tuDate = new Date(k.displayDate)
            if (tuDate.getTime() >= lastTuDate.getTime()) {
              lastTU = k.amount
              lastTuDate = tuDate
              lastName = k.name
              if (k.transferRef) {
                lastTfNum = k.transferRef.transferNumber
                lastTfDate = new Date(k.transferRef.transferDate)
              }
            }
          }
        }
        data.total = data.total / 800
        res.push({
          ...data,
          subject: j.subject.toUpperCase(),
          topup: lastTU / 800,
          topupDate: lastTuDate,
          transferNumber: lastTfNum,
          transferDate: lastTfDate,
          type: lastName,
        })
      }
    }
    const allUsers = vxm.allUsers.allStudents
    if (allUsers.length >= 0) {
      return res.filter(
        el =>
          el.total <= this.margin &&
          allUsers.findIndex(e => e.ID === Number(el.username)) >= 0
      )
    }
    return res.filter(el => el.total <= this.margin)
  }

  get totalCoin() {
    const coins = this.getSummaryTransaction
    return coins.reduce<number>((sum, curr) => {
      return (sum += curr.total)
    }, 0)
  }

  @mutation updateTransactionFetchStatus(payload: boolean) {
    this.onFetch = payload
  }
  @mutation updateTransaction(payload: AllTransaction[]) {
    this.transaction = payload
  }
  @mutation updateTransactionMargin(payload: number) {
    this.margin = payload
  }

  @action async fetchTransaction() {
    const queryAllUserTransaction = apolloClient.query<{
      users: AllTransaction[]
    }>({
      query: gql`
        query Transactions {
          users {
            id
            username
            firstName
            firstNameEn
            lastName
            lastNameEn
            nickname
            nicknameEn
            email
            position
            phone
            walletSummaries {
              subject
              transactions {
                amount
                displayDate
                tags
                expireIn
                name
                remark
                transferRef {
                  transferNumber
                  transferDate
                }
              }
              wallets {
                name
                balance
                expireIn
                expiryDate
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
    this.updateTransactionFetchStatus(true)
    vxm.allUsers.fetchUsers()
    try {
      const data = await queryAllUserTransaction
      this.updateTransaction(data.data.users)
    } catch (error) {
      // empty
    }
    this.updateTransactionFetchStatus(false)
  }
}
