













import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Navigation from '@/components/Navigation.vue'

@Component({
  components: {
    Navbar,
    Footer,
    Navigation,
  },
})
export default class MainContainer extends Vue {}
