import { createModule, mutation, action } from 'vuex-class-component'
import { apolloClient } from '@/utils/graphql'
import gql from 'graphql-tag'
import {
  AllTransaction,
  Wallet,
  UserPosition,
  CoinTransactionByUsernameInput,
  Transaction,
  CoinTransactionSubject,
  CoinTransactionTag,
  TransactionName,
  TransferRef,
} from '@/types/transaction'

const VuexModule = createModule({
  namespaced: 'userTransaction',
  strict: false,
})

const getSubjectStr = (subjectKey: string) => {
  switch (subjectKey) {
    case '1':
      return CoinTransactionSubject.math
    case '2':
      return CoinTransactionSubject.physics
    case '3':
      return CoinTransactionSubject.chem
    case '4':
      return CoinTransactionSubject.english
    default:
      return CoinTransactionSubject.math
  }
}

export default class extends VuexModule {
  private subject = CoinTransactionSubject.math
  private onFetch = false
  private transaction: AllTransaction = {
    id: '',
    username: '',
    firstName: '',
    firstNameEn: '',
    lastName: '',
    lastNameEn: '',
    nickname: '',
    nicknameEn: '',
    email: '',
    position: UserPosition.Student,
    phone: [],
    walletSummaries: [],
  }

  get fetchStatus() {
    return this.onFetch
  }
  get getSubject() {
    return this.subject.toUpperCase()
  }
  get userInfo() {
    return {
      username: this.transaction.username,
      firstName: this.transaction.firstName,
      nickname: this.transaction.nickname,
    }
  }
  get userWallet() {
    let res: Wallet[] = []
    for (const i of this.transaction.walletSummaries) {
      if (i.subject === this.subject) {
        res = i.wallets
        break
      }
    }
    return res
  }
  get userTransaction() {
    let res: Transaction[] = []
    for (const i of this.transaction.walletSummaries) {
      if (i.subject === this.subject) {
        res = i.transactions
          .map(el => {
            return { ...el, displayDate: new Date(el.displayDate) }
          })
          .sort((a, b) => b.displayDate.valueOf() - a.displayDate.valueOf())
        break
      }
    }
    return res
  }
  get userTotalCoin() {
    let res = 0
    for (const i of this.transaction.walletSummaries) {
      if (i.subject === this.subject) {
        for (const j of i.wallets) {
          res += j.balance
        }
        break
      }
    }
    return res
  }

  @mutation updateFetchStatus(state: boolean) {
    this.onFetch = state
  }
  @mutation updateTransaction(transaction: AllTransaction) {
    this.transaction = transaction
  }
  @mutation updateSubj(subject: string) {
    this.subject = getSubjectStr(subject)
  }

  @action async fetchUserTransacction(username: string) {
    const queryUserTransaction = apolloClient.query<{
      user: AllTransaction
    }>({
      query: gql`
        query UserTransactions($username: String!) {
          user(username: $username) {
            id
            username
            firstName
            firstNameEn
            lastName
            lastNameEn
            nickname
            nicknameEn
            email
            position
            phone
            walletSummaries {
              subject
              transactions {
                amount
                displayDate
                tags
                expireIn
                name
                remark
              }
              wallets {
                name
                balance
                expireIn
                expiryDate
              }
            }
          }
        }
      `,
      variables: { username: username.slice(0, -1) },
      fetchPolicy: 'no-cache',
    })
    this.updateFetchStatus(true)
    try {
      const data = await queryUserTransaction
      this.updateTransaction(data.data.user)
      this.updateSubj(username.slice(-1))
      this.updateFetchStatus(false)
    } catch (error) {
      this.updateFetchStatus(false)
    }
  }
  @action async createTransaction(transaction: CoinTransactionByUsernameInput) {
    const mutateFhbTrans = apolloClient.mutate<{
      createCoinTransactionByUsername: { user: AllTransaction }
    }>({
      mutation: gql`
        mutation FhbTrans($data: CoinTransactionByUsernameInput!) {
          createCoinTransactionByUsername(data: $data) {
            user {
              id
              username
              firstName
              firstNameEn
              lastName
              lastNameEn
              nickname
              nicknameEn
              email
              position
              phone
              walletSummaries {
                subject
                transactions {
                  amount
                  displayDate
                  tags
                  expireIn
                  name
                  remark
                }
                wallets {
                  name
                  balance
                  expireIn
                  expiryDate
                }
              }
            }
          }
        }
      `,
      variables: {
        data: transaction,
      },
    })
    try {
      const res = await mutateFhbTrans
      if (res.data) {
        this.updateTransaction(res.data.createCoinTransactionByUsername.user)
      }
    } catch (error) {
      // empty
    }
  }
  @action async createFhbTransaction(folderId: string) {
    const data: CoinTransactionByUsernameInput = {
      username: folderId.slice(0, -1),
      amount: -800,
      subject: getSubjectStr(folderId.slice(-1)),
      tags: [CoinTransactionTag.Fhb],
      name: TransactionName.empty,
    }
    this.updateSubj(folderId.slice(-1))
    await this.createTransaction(data)
  }
  @action async createFhbMnTransaction(folderId: string, remark = 'MN') {
    const data: CoinTransactionByUsernameInput = {
      username: folderId.slice(0, -1),
      amount: -400,
      subject: getSubjectStr(folderId.slice(-1)),
      tags: [CoinTransactionTag.Fhb],
      name: TransactionName.empty,
      remark: remark,
    }
    this.updateSubj(folderId.slice(-1))
    await this.createTransaction(data)
  }
  @action async createRefundTransaction(params: {
    amount: number
    reason: string
  }) {
    const data: CoinTransactionByUsernameInput = {
      username: this.userInfo.username,
      amount: params.amount * 800,
      subject: this.subject,
      tags: [CoinTransactionTag.Refund],
      name: TransactionName.empty,
      remark: params.reason,
    }
    await this.createTransaction(data)
  }
  @action async createFineTransaction(params: {
    amount: number
    reason: string
  }) {
    const data: CoinTransactionByUsernameInput = {
      username: this.userInfo.username,
      amount: -(params.amount * 800),
      subject: this.subject,
      tags: [CoinTransactionTag.Fine],
      name: TransactionName.empty,
      remark: params.reason,
    }
    await this.createTransaction(data)
  }
  @action async createAbsentTransaction(amount: number) {
    const data: CoinTransactionByUsernameInput = {
      username: this.userInfo.username,
      amount: -(amount * 800),
      subject: this.subject,
      tags: [CoinTransactionTag.Absent],
      name: TransactionName.empty,
    }
    await this.createTransaction(data)
  }
  @action async createDropTransaction(amount: number) {
    const data: CoinTransactionByUsernameInput = {
      username: this.userInfo.username,
      amount: -(amount * 800),
      subject: this.subject,
      tags: [CoinTransactionTag.Drop],
      name: TransactionName.empty,
    }
    await this.createTransaction(data)
  }
  @action async createCustomFhbTransaction(params: {
    amount: number
    reason: string
    displayDate: Date
  }) {
    const data: CoinTransactionByUsernameInput = {
      username: this.userInfo.username,
      amount: -(params.amount * 800),
      displayDate: params.displayDate,
      subject: this.subject,
      tags: [CoinTransactionTag.Fhb],
      name: TransactionName.empty,
      remark: params.reason.toUpperCase(),
    }
    await this.createTransaction(data)
  }
  @action async createTopupTransaction(params: {
    amount: number
    name: TransactionName
    transactionNumber: string
    transactionDate: string
  }) {
    const transferRef: TransferRef = {
      transferNumber: params.transactionNumber,
      transferDate: params.transactionDate,
    }
    const data: CoinTransactionByUsernameInput = {
      username: this.userInfo.username,
      amount: params.amount * 800,
      subject: this.subject,
      tags: [CoinTransactionTag.TopUp],
      name: params.name,
      transferRef: transferRef,
    }
    await this.createTransaction(data)
  }
}
