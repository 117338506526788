import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { vxm } from '@/store'

Vue.use(VueApollo)

const url = process.env.VUE_APP_OFFICE_URL

const httpLink = createHttpLink({
  uri: `${url}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = vxm.login.getToken
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

export default apolloProvider
