import { RouteConfig } from 'vue-router'

export const managementRoutes: RouteConfig[] = [
  {
    path: '',
    redirect: '/auth',
  },
  {
    path: 'auth',
    name: 'Auth Management',
    component: () =>
      import(
        /* webpackChunkName: "management.auth" */ '../views/Management/Auth.vue'
      ),
    meta: {
      title: 'Auth Management',
    },
  },
  {
    path: 'office',
    name: 'Office Management',
    component: () =>
      import(
        /* webpackChunkName: "management.office" */ '../views/Management/Office.vue'
      ),
    meta: {
      title: 'Course Management',
    },
  },
  {
    path: 'users',
    name: 'Users Management',
    component: () =>
      import(
        /* webpackChunkName: "management.users" */ '../views/Management/Users.vue'
      ),
    meta: {
      title: 'Users Management',
    },
  },
  {
    path: 'quarter',
    name: 'Quarter Management',
    component: () =>
      import(
        /* webpackChunkName: "management.quarter" */ '../views/Management/Quarter.vue'
      ),
    meta: {
      title: 'Quarter Management',
    },
  },
  {
    path: 'course',
    name: 'Course Management',
    component: () =>
      import(
        /* webpackChunkName: "management.course" */ '../views/Management/Course.vue'
      ),
    meta: {
      title: 'Course Management',
    },
  },
  {
    path: 'room',
    name: 'Room Management',
    component: () =>
      import(
        /* webpackChunkName: "management.room" */ '../views/Management/Room.vue'
      ),
    meta: {
      title: 'Room Management',
    },
  },
  {
    path: 'hybrid',
    name: 'Hybrid Management',
    component: () =>
      import(
        /* webpackChunkName: "management.hybrid" */ '../views/Management/Hybrid.vue'
      ),
    meta: {
      title: 'Hybrid Management',
    },
  },
  {
    path: 'timetable',
    name: 'Timetable Management',
    component: () =>
      import(
        /* webpackChunkName: "management.timetable" */ '../views/Management/Timetable.vue'
      ),
    meta: {
      title: 'Timetable Management',
    },
  },
]
