import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import MainContainer from '@/views/MainContainer.vue'
import { vxm } from '@/store'
import { managementRoutes } from './management'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    component: MainContainer,
    children: [
      {
        path: '/',
        redirect: '/home',
      },
      {
        path: '/home',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
          title: 'Home',
        },
      },
      {
        path: '/coin-summary',
        name: 'CoinSummary',
        component: () =>
          import(
            /* webpackChunkName: "coin-summary" */ '../views/CoinSummary.vue'
          ),
        meta: {
          title: 'Coin Summary',
        },
      },
      {
        path: '/admin',
        name: 'Admin',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
        meta: {
          title: 'Admin',
        },
      },
      {
        path: '/student',
        name: 'Student',
        component: () =>
          import(
            /* webpackChunkName: "student-list" */ '../views/StudentList.vue'
          ),
        meta: {
          title: 'Student',
        },
      },
      {
        path: '/student/:userId',
        name: 'Student info',
        component: () =>
          import(/* webpackChunkName: "student" */ '../views/Student.vue'),
        meta: {
          title: 'Student Info',
        },
      },
      {
        path: '/register-list',
        name: 'Register List',
        component: () =>
          import(
            /* webpackChunkName: "register" */ '../views/RegisterList.vue'
          ),
        meta: {
          title: 'Register List',
        },
      },
      {
        path: '/top-up-request',
        name: 'Top Up Request',
        component: () =>
          import(
            /* webpackChunkName: "register" */ '../views/TopUpRequest.vue'
          ),
        meta: {
          title: 'Top Up Request',
        },
      },
      {
        path: '/new-student-test',
        name: 'New Student Test',
        component: () =>
          import(
            /* webpackChunkName: "register" */ '../views/NewStudentTest.vue'
          ),
        meta: {
          title: 'New Student Test',
        },
      },
      {
        path: '/management',
        component: () =>
          import(
            /* webpackChunkName: "management" */ '../views/Management/Management.vue'
          ),
        children: managementRoutes,
      },
      {
        path: '/announcement-uploader',
        component: () =>
          import(
            /* webpackChunkName: "announcement-uploader" */ '../views/Announcement/AnnouncementUploader.vue'
          ),
        meta: {
          title: 'Announcement Uploader',
        },
      },
      {
        path: '/timelog-management/:userIntervalId/:userId',
        name: 'TimeSlotManagement',
        component: () =>
          import(
            /* webpackChunkName: "timeslot-management" */ '../views/TimeSlotManagement.vue'
          ),
        meta: {
          title: 'Time-slot Management',
        },
        beforeEnter: (to, from, next) => {
          const users = [
            '99001',
            '99002',
            '99008',
            '99009',
            '99021',
            '99023',
            '99164',
          ]
          if (users.findIndex(el => el === vxm.login.myUsername) >= 0) {
            next()
          } else {
            next('/no-permission')
          }
        },
      },
      {
        path: '/timelog-management',
        name: 'TimeLogManagement',
        component: () =>
          import(
            /* webpackChunkName: "timelog-management" */ '../views/TimeLogManagement.vue'
          ),
        meta: {
          title: 'Time-log Management',
        },
        beforeEnter: (to, from, next) => {
          const users = [
            '99001',
            '99002',
            '99008',
            '99009',
            '99021',
            '99023',
            '99164',
          ]
          if (users.findIndex(el => el === vxm.login.myUsername) >= 0) {
            next()
          } else {
            next('/no-permission')
          }
        },
      },
      {
        path: '/no-permission',
        name: 'NoPermission',
        component: () =>
          import(
            /* webpackChunkName: "no-permission" */ '../views/NoPermission.vue'
          ),
        meta: {
          title: 'No Permission',
        },
      },
    ],
  },
  // {
  //   path: '/fhb-checkout',
  //   name: 'FhbCheckout',
  //   component: () =>
  //     import(/* webpackChunkName: "fhb-checkout" */ '../views/FhbCheckOut.vue'),
  //   meta: {
  //     title: 'Check out',
  //   },
  // },
  {
    path: '/fhb-checkout-2',
    name: 'FhbCheckout2',
    component: () =>
      import(
        /* webpackChunkName: "fhb-checkout-2" */ '../views/FhbCheckOut2.vue'
      ),
    meta: {
      title: 'Check out',
    },
  },
  {
    path: '/announcement',
    component: () =>
      import(
        /* webpackChunkName: "announcement" */ '../views/Announcement/Announcement.vue'
      ),
    meta: {
      title: 'Announcement',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function setTitleByMeta(route: Route) {
  const nearestWithTitle = route.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }
}

router.beforeEach(async (to, from, next) => {
  if (vxm.login.isLogin) {
    setTitleByMeta(to)
    next()
  } else if (to.query.ticket) {
    await vxm.login.login(to.query.ticket as string)
    next(to.path)
  } else {
    window.location.replace(
      `${process.env.VUE_APP_AUTH_URL}/auth?callback=${encodeURIComponent(
        `${process.env.VUE_APP_BASE_URL}${to.path}`
      )}`
    )
  }
})

export default router
