import { createModule, mutation } from 'vuex-class-component'

const VuexModule = createModule({
  namespaced: 'timelog',
  strict: false,
})

export default class extends VuexModule {
  private selectIntervalId = 0

  public get getSelectIntervalId() {
    return this.selectIntervalId
  }

  @mutation updateSelectIntervalId(payload: number) {
    this.selectIntervalId = payload
  }
}
