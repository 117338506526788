import colors from 'vuetify/es5/util/colors'

export const theme = {
  light: {
    primary: '#eee',
    opposite: '#000',
    secondary: colors.amber.darken2,
    accent: colors.teal.base,
    error: colors.deepOrange.base,
    warning: colors.orange.base,
    info: colors.lightBlue.base,
    success: colors.green.base,
    invert: colors.grey.lighten2,
    math: colors.amber.lighten2,
    phys: colors.deepPurple.accent1,
    chem: colors.lightBlue.lighten2,
    engl: colors.grey.lighten2,
    scie: colors.lightGreen.lighten2,
    nursery: colors.lightGreen.lighten3,
    kids: colors.yellow.lighten3,
    monColor: colors.yellow.base,
    tueColor: colors.pink.lighten3,
    wedColor: colors.lightGreen.base,
    thuColor: colors.orange.base,
    friColor: colors.lightBlue.lighten1,
    satColor: colors.purple.lighten3,
    sunColor: colors.red.lighten1,
  },
  dark: {
    primary: '#000',
    opposite: '#eee',
    secondary: colors.amber.base,
    accent: colors.cyan.base,
    error: colors.deepOrange.base,
    warning: colors.orange.base,
    info: colors.lightBlue.base,
    success: colors.green.base,
    invert: colors.grey.darken2,
    math: colors.amber.darken2,
    phys: colors.deepPurple.darken1,
    chem: colors.lightBlue.darken2,
    engl: colors.grey.darken1,
    scie: colors.lightGreen.darken1,
    nursery: colors.lightGreen.accent2,
    kids: colors.yellow.accent2,
    monColor: colors.amber.darken1,
    tueColor: colors.pink.base,
    wedColor: colors.green.base,
    thuColor: colors.orange.darken2,
    friColor: colors.lightBlue.base,
    satColor: colors.purple.base,
    sunColor: colors.red.base,
  },
}
